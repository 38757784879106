<template>
  <div class="app">
    <el-card class="card">

      <el-row :md="24" class="head">
        <el-col :md="12">
          <el-col :span="12" class="item">
            <el-select v-model="queryDb.storeType" filterable placeholder="選擇門店">
              <el-option label="所有門店" value="0"></el-option>
              <el-option
                v-for="item in storeList"
                :key="item.id"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-col>

          <!-- <el-col :span="6" class="item">
            <el-select v-model="queryDb.teamType" filterable  placeholder="選擇Team">
              <el-option label="所有Team" value="0"></el-option>
              <el-option
                v-for="item in teamList"
                :key="item.id"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-col> -->

          <el-col :span="12" class="item">

            <div class="el-date-editor el-range-editor el-input__inner el-date-editor--daterange is-active" style="width: 100%;">
              <i class="fa fa-jpy"></i>
              <input autocomplete="off" placeholder="10000" v-model="queryDb.moneyMin" class="el-range-input">
              <span class="el-range-separator">~</span>
              <input autocomplete="off" placeholder="50000" v-model="queryDb.moneyMax" class="el-range-input">
              <!-- <span class="el-range-separator">萬</span> -->
            </div>

          </el-col>
        </el-col>
        <el-col :md="6" class="item query">
          <el-button type="primary" @click="getInvoiceStatisticsList">查 詢</el-button>
        </el-col>
        <el-col :md="6" class="item totalAmountSpent">
          总消费金额:{{totalMoneyAll?'￥'+totalMoneyAll:'￥00.00'}}
        </el-col>
      </el-row>

    </el-card>

    <el-card class="card">


      <el-row :md="24" class="item">
        <template>
          <el-table
            :data="tableData"
            v-loading="loading"
            :default-sort = "{prop: 'date', order: 'descending'}"
            style="width: 100%">
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-form label-position="left" inline class="table-expand">
                  <el-form-item label="姓名">
                    <span>{{ props.row.name }} <el-tag v-if="props.row.storeName" size="mini" type="success" effect="plain">{{props.row.storeName}}</el-tag></span>
                  </el-form-item>
                  <el-form-item label="手提號碼">
                    <span>{{ props.row.phone }} {{props.row.phone2?','+props.row.phone2:''}}</span>
                  </el-form-item>
                </el-form>
              </template>
            </el-table-column>

            <el-table-column label="會員編號" prop="memberId"></el-table-column>
            <el-table-column label="最早發票號碼" prop="minNo"></el-table-column>
            <el-table-column label="最新發票號碼" prop="maxNo"></el-table-column>
            <el-table-column label="最早開單時間" prop="minDate" sortable></el-table-column>
            <el-table-column label="最新開單時間" prop="naxDate" sortable></el-table-column>
            <el-table-column label="总消费金额" prop="total" sortable ></el-table-column>
          </el-table>
        </template>
      </el-row>
    </el-card>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        loading:false,
        queryDb:{
          storeType:'0',
          teamType:'0',
          moneyMin:10000,
          moneyMax:50000,
        },
        storeList:[],
        teamList:[],
        tableData:[],
        totalMoneyAll:"00.00",
      }
    },
    mounted(){
      this.getStoreList()
      //this.getTeamList()
    },
    methods:{
      // unix转时间格式
    unixToTime(date){
      return this.$atk.unixToTime("Y-m-d",date)
    },
      // 获取發票統計列表
      getInvoiceStatisticsList() {
        let that = this;
        let param = that.$atk.json_to_url({
          storeType:that.queryDb.storeType,
          //teamType:that.queryDb.teamType,
          moneyMin:that.queryDb.moneyMin,
          moneyMax:that.queryDb.moneyMax,
        });
        if(!that.queryDb.moneyMin || !that.queryDb.moneyMax){
          that.$message({
            message: "請填寫查詢金額範圍。",
            type: "warning",
          });
          return
        }
        that.loading = true;
        that.$http.GET(
          "/v1/booking/getInvoiceStatisticsList?"+param,(ret)=> {
            that.loading = false;
            if (ret.errcode == 0) {
              let list = ret.data;
              that.totalMoneyAll = ret.totalMoneyAll
              let arrayDb =[]
              for(let i in list){
                let datas = {
                  ...list[i],
                  minDate:that.unixToTime(list[i].minDate),
                  naxDate:that.unixToTime(list[i].naxDate),
                  total:'￥'+list[i].total
                }
                arrayDb.push(datas)
              }
              that.tableData = arrayDb
            } else if (ret.errcode >= 1) {
              that.$message({
                message: ret.errmsg,
                type: "warning",
              });
            }
          },(err)=> {
            that.loading = false;
            that.$notify({
              title: "警告",
              message: err,
              type: "warning",
            });
          }
        );
      },
      // 获取門店列表
      getStoreList() {
        let that = this;
        that.$http.GET(
          "/v1/booking/getStoreList",
          function (ret) {
            if (ret.errcode == 0) {
              let list = ret.data.list
              for(let i in list){
                that.storeList.push({
                  id:list[i].id,
                  value:list[i].id,
                  label:list[i].title,
                })
              }
            } else if (ret.errcode >= 1) {
              that.$message({
                message: ret.errmsg,
                type: "warning",
              });
            }
          },
          function (err) {
            that.$notify({
              title: "警告",
              message: err,
              type: "warning",
            });
          }
        );
      },
      // 获取團隊列表
      getTeamList() {
        let that = this;
        that.$http.GET(
          "/v1/booking/getTeamList",
          function (ret) {
            if (ret.errcode == 0) {
              let list = ret.data
              for(let i in list){
                that.teamList.push({
                  id:list[i].id,
                  value:list[i].id,
                  label:list[i].name,
                })
              }
            } else if (ret.errcode >= 1) {
              that.$message({
                message: ret.errmsg,
                type: "warning",
              });
            }
          },
          function (err) {
            that.$notify({
              title: "警告",
              message: err,
              type: "warning",
            });
          }
        );
      },
    },
  }
</script>
<style scoped lang="scss">
.card{
  .item{
    padding: 6px;
  }
  .query{
    text-align: left;
  }
  .totalAmountSpent{
    padding-top: 13px;
    text-align: right;
  }
  .table-expand {
    font-size: 0;
    label {
      width: 90px;
      color: #99a9bf;
    }
    .el-form-item {
      margin-right: 0;
      margin-bottom: 0;
      width: 50%;
    }
  }
}
</style>
